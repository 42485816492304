import { LitElement, html, css } from 'lit';
import './header-component.js';
import './footer-component.js';
import './pages/home-page.js';
import GridStyle from '../styles/grid.js';

export class AppShell extends LitElement {
  static styles = css`
    ${GridStyle}
    :host {
      display: block;
      height: 100vh;
    }
    main {
      padding: 16px 0;
      margin-top: -300px;
    }
    .grid {
      height: 100%;
    }
    @media (max-width: 768px) {
      main {
        padding: 0 0 16px;
        margin-top: -16px;
      }
    }
  `;

  render() {
    return html`
      <div class="grid">
        <header-component class="col-12 col-mobile-4"></header-component>
        <main class="col-12 col-mobile-4">
          <home-page></home-page>
        </main>
        <footer-component class="col-12 col-mobile-4"></footer-component>
      </div>
    `;
  }
}
