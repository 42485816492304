import { LitElement, html, css } from 'lit';

class ConcienciaComponent extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      position: relative;
      text-align: right;
    }
    img {
      max-width: 454px;
      height: auto;
      position: relative;
      margin-right: 40px;
    }
    .enlace-desktop {
      position: absolute;
      bottom: 70px;
      right: 240px;
    }
    .enlace {
      background: #731110;
      border-radius: 20px;
      width: 147px;
      height: 30px;
      color: white;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }
    .enlace:hover {
      color: #731110;
      background: white;
    }

    .conciencia-inline {
      width: 100%;
      background-color: #2a3770;
      margin: 0 auto;
      padding: 20px 0;
      text-align: left;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: space-evenly;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: #c2c4d4;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: #8489a9;
    }

    .only-desktop {
      display: block;
    }
    .only-mobile {
      display: none;
    }

    @media (max-width: 768px) {
      :host {
        position: absolute;
        bottom: 0px;
        left: 0;
        text-align: center;
      }
      .only-mobile {
        display: block;
      }
      .only-desktop {
        display: none;
      }
    }
  `;

  render() {
    return html`
      <div class="only-desktop">
        <img src="../assets/conciencia.png" />
        <a
          href="https://www.youtube.com/@concienciaesconsciencia"
          title="Ir al Canal de Youtube Conciencia es Consciencia"
          target="_blank"
          class="enlace enlace-desktop"
          >VISITAR CANAL</a
        >
      </div>
      <div class="only-mobile">
        <div class="conciencia-inline">
          <div>
            <h2>Conciencia es Consciencia</h2>
            <p>Visita nuestro canal </br> en <b>Youtube</b></p>
          </div>
          <a
            href="https://www.youtube.com/@concienciaesconsciencia"
            title="Ir al Canal de Youtube Conciencia es Consciencia"
            target="_blank"
            class="enlace"
            >VISITAR CANAL</a
          >
        </div>
      </div>
    `;
  }
}

customElements.define('conciencia-component', ConcienciaComponent);
