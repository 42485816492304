// header-component.js
import { LitElement, html, css } from 'lit';
import './shared/conciencia-component.js';
import './shared/mandalas-component.js';

class HeaderComponent extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      height: 790px;
      background: url('./assets/header-extra-large-1280.jpg') center top
        no-repeat;
      padding: 0;
      margin: 0 auto;
      position: relative;
    }
    header {
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: space-evenly;
    }

    @media (max-width: 768px) {
      :host {
        height: 450px;
      }
    }
  `;

  render() {
    return html`
      <header>
        <conciencia-component></conciencia-component>
        <mandalas-component></mandalas-component>
      </header>
    `;
  }
}

customElements.define('header-component', HeaderComponent);
