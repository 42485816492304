import { LitElement, html, css } from 'lit';
import { map } from 'lit/directives/map.js';
import GridStyle from '../../styles/grid.js';

class HomePage extends LitElement {
  static styles = css`
    ${GridStyle}
    :host {
      display: block;
      position: relative;
    }
    .only-mobile {
      display: none;
    }
    .only-desktop {
      display: block;
    }
    section {
      margin: 0 auto;
      max-width: 1024px;
    }
    .libros-section {
      padding: 20px;
    }
    .content {
      padding: 20px;
      border-radius: 20px 20px 0 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0) 400px
      );
    }

    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    .title {
      color: #12325b;
      text-align: center;
    }
    .title2 {
      color: #e0d9d0;
    }
    .subtitle {
      color: #aca89b;
    }
    .content > p {
      color: #12325b;
    }
    .libros-content {
      background-color: #79735f;
      padding: 0 0 30px;
    }
    ul.libros-listado {
      margin: 0;
      padding: 0;
    }
    li.libros-item {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    @media (max-width: 768px) {
      .content {
        border-radius: 0;
      }
      .only-mobile {
        display: block;
      }
      .only-desktop {
        display: none;
      }
    }
  `;

  constructor() {
    super();
    this._libros = [
      {
        file: '1Chacra.pdf',
        src: 'libro-chakra1.jpg',
        title: 'Libro de los Chakras 1',
      },
      {
        file: '2Chacra.pdf',
        src: 'libro-chakra2.jpg',
        title: 'Libro de los Chakras 2',
      },
      {
        file: '3Chacra.pdf',
        src: 'libro-chakra3.jpg',
        title: 'Libro de los Chakras 3',
      },
      {
        file: '4Chacra.pdf',
        src: 'libro-chakra4.jpg',
        title: 'Libro de los Chakras 4',
      },
      {
        file: '5Chacra.pdf',
        src: 'libro-chakra5.jpg',
        title: 'Libro de los Chakras 5',
      },
      {
        file: '6Chacra.pdf',
        src: 'libro-chakra6.jpg',
        title: 'Libro de los Chakras 6',
      },
      {
        file: '7Chacra.pdf',
        src: 'libro-chakra7.jpg',
        title: 'Libro de los Chakras 7',
      },
      {
        file: '8Chacra.pdf',
        src: 'libro-chakra8.jpg',
        title: 'Libro de los Chakras 8',
      },
      {
        file: '9Chacra.pdf',
        src: 'libro-chakra9.jpg',
        title: 'Libro de los Chakras 9',
      },
    ];
  }

  render() {
    return html`
      <section class="grid">
        <div class="col-2 only-desktop"></div>
        <div class="col-8 col-mobile-4 content">
          <h2 class="title">MI AMOR POR TI</h2>
          <p>
            Si viendo a tu sufrir, quiero amarte; deja que guíe a tu sentir por
            donde enfiles tu corazón hacía el mejoramiento de lo que somos.
          </p>
          <p>
            Si viendo inútil tu existir, prefieres proseguir por donde mi sentir
            te haga mella; no ensucies el caudal y recibirás en ti la belleza
            que guardas, para hacerte revivir los momentos más felices que te
            esperan, si permites que me adentre hacia el llenado de un Alma
            disponible para hacer de ti, mi vida.
          </p>
          <p>
            Si viendo la calamidad arrimarse, no permites el arrecio de quien
            más te ama; deja que sea mi voz quien ilumine la estancia y puedas
            resurgir, viendome como te indico la manera de poder ser feliz. Eres
            para mí la existencia que tengo. Hazme un hueco por donde
            demostrarte mi fidelidad a tí, que eres lo que amo, y servirte hasta
            el límite de lo que soy, al confiarte de quién te hizo nacer para
            darte lo que és tuyo; mi amor por tí. Suspirando vivo para llegar a
            tí: y te hablo para que recibas el apoyo de quien será tu vida,
            cuando recibas desde tu pecho lo que siento por quién más amo.
          </p>
          <p>Es la vida lo que tengo para ofrecerte.</p>
          <p>Es el amor lo que fluye para ayudarte.</p>
          <p>
            Es mi corazón deseoso por complacerte, y surja por ti lo que llevas;
            mi amor por todos vosotros.
          </p>
          <p>
            ¡Os amo! Por eso estoy aquí y sintáis como Yo la vida que os dí.
            Reconociéndome así, por quien quiso al fin exteriorizarme en esperas
            de repartir lo más tierno; el amor hacia todos los nacidos por mi
            corazón.
          </p>
          <p>
            El Creador dispuesto a sucumbir, sólo por amor a ti. Strón Nel:
            Jesús de Nazaret en Jesdaymi.
          </p>
          <p>¡Te amo en la eternidad!</p>
        </div>
        <div class="col-2 only-desktop"></div>
      </section>

      <div class="libros-content">
        <section class="libros-section grid">
          <div class="col-2 only-desktop"></div>
          <div class="col-8 col-mobile-4">
            <h2 class="title2">DESCARGA LOS LIBROS</h2>
            <p class="subtitle">
              Explora la colección completa de libros escritos por
              <b>Jesdaymi el Alma Stron Nel</b> y descárgalos en formato PDF.
            </p>
            <ul class="libros-listado grid">
              ${map(
                this._libros,
                ({ file, src, title }) =>
                  html`<li class="col-2 col-mobile-2 libros-item">
                    <a href="./files/${file}" target="_blank" alt="${title}"
                      ><img
                        width="100%"
                        src="../assets/${src}"
                        title="${title}"
                    /></a>
                  </li>`,
              )}
            </ul>
          </div>
          <div class="col-2 only-desktop"></div>
        </section>
      </div>
    `;
  }
}

customElements.define('home-page', HomePage);
