// footer-component.js
import { LitElement, html, css } from 'lit';

class FooterComponent extends LitElement {
  static styles = css`
    footer {
      padding: 1rem;
      text-align: center;
    }
  `;

  render() {
    return html` <footer></footer> `;
  }
}

customElements.define('footer-component', FooterComponent);
