import { LitElement, html, css } from 'lit';

class MandalasComponent extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      height: auto;
      text-align: left;
    }
    .mandala {
      width: 100%;
      height: auto;
      position: relative;
      margin-left: -5%;
    }

    @media (max-width: 768px) {
      :host {
        text-align: center;
      }
      .mandala {
        width: auto;
        height: 360px;
        margin: 0 auto;
      }
    }
  `;

  render() {
    return html` <img class="mandala" src="../assets/mandalas.png" /> `;
  }
}

customElements.define('mandalas-component', MandalasComponent);
